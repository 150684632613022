td,
th {
  padding: 0 0;
}

.calender {
  width: 30% !important;
  font-size: 12px !important;
  display: inline-flex !important;
}

.form-control {
  height: 20px !important;
  border: 1px solid #808080 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: center !important;
  border-radius: 2px !important;
}

.rdt {
  padding-right: 5px !important;
}
