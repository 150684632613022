#number-widget {
  height: 25%;
  width: 100%;
  color: white;
  background-color: orange;
  text-align: center;
  margin: auto;
  padding: 2px;
}

#number-widget h3 {
  font-size: 25px;
}
